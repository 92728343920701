import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import theme from '../theme'
import dot from '../assets/images/blog/dot.svg'
import star from '../assets/images/blog/star.svg'
import { SecondaryCTA } from '../components/SecondaryCTA'
import Block from '../components/Block'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import MetaImage from '../assets/images/blog-meta.png'

const Blog = ({ data }) => {
  return (
    <Layout
      justifyCenter
      title="Blog"
      description="If you’re interested in building happy and high-performing software teams, you’ve come to the right place."
      metaImage={MetaImage}
    >
      <BackgroundWrapper>
        <Block size="medium">
          <Container>
            <div className="img-container">
              <img className="dot" src={dot} aria-hidden="true" />
              <img className="star" src={star} aria-hidden="true" />
            </div>
            <h1 className="title">Swarmia Blog</h1>
            <h6>Latest articles</h6>
            <Posts data={data} />
            <SecondaryCTA />
          </Container>
        </Block>
      </BackgroundWrapper>
    </Layout>
  )
}

export default Blog

const Posts = ({ data }) => {
  const {
    allMdx: { edges },
  } = data
  return edges.map(
    ({
      node: {
        excerpt,
        frontmatter: { slug, title, author, date, meta, showCoverImage },
      },
    }) => {
      const cover = getImage(meta.image)
      return (
        <BlogPostWrapper key={slug}>
          <article key={slug}>
            <a href={slug}>
              <Post>
                <h2>{title}</h2>
                <div className="meta">
                  {author.name} · {date}
                </div>
                {cover && showCoverImage && (
                  <GatsbyImage image={cover} alt={meta.imageAlt} />
                )}
                <p className="excerpt">{excerpt}</p>
                <div className="read-story">
                  <h4>Read now</h4>
                  <span> →</span>
                </div>
              </Post>
            </a>
          </article>
        </BlogPostWrapper>
      )
    },
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/src/blog/" } }
    ) {
      ...BlogPostsFragment
    }
  }

  fragment BlogPostsFragment on MdxConnection {
    edges {
      node {
        excerpt(pruneLength: 192)
        frontmatter {
          date(formatString: "MMM D, YYYY")
          slug
          title
          showCoverImage
          author {
            name
            jobTitle
          }
          meta {
            image {
              childImageSharp {
                gatsbyImageData(width: 1000, quality: 90)
                original {
                  src
                  width
                  height
                }
              }
            }
            imageAlt
          }
        }
      }
    }
  }
`

const BackgroundWrapper = styled.div`
  background-color: #fafafa;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;

  @media screen and (max-width: 44rem) {
    padding: 2rem 0;
  }

  div.img-container {
    display: flex;
    margin-bottom: 2.2rem;
    @media screen and (max-width: 44rem) {
      margin-bottom: 1.5rem;
    }
    img.dot {
      position: relative;
      left: 40%;
      z-index: 0;
    }
    img.star {
      margin-top: 5px;
      z-index: 1;
    }
  }

  h6 {
    margin: 1rem 0 2rem;

    @media screen and (min-width: ${theme.breakpoints.medium}) {
      margin: 1.5rem 0 3rem;
    }
  }

  a {
    display: block;
    transition: transform 0.2s ease-in-out;

    //same as in CustomerStoryTile

    .read-story {
      margin-top: 0.75rem;

      h4 {
        display: inline;
        text-decoration: underline;
        text-decoration-color: #f3b3e0;
        text-underline-offset: 6px;
      }

      span {
        transition: all 0.15s ease-out;
        position: relative;
        top: 1px;
        left: -2px;
        font-weight: 500;
      }
    }

    &:hover {
      transform: scale(1.02);

      .read-story {
        h4 {
          text-decoration-color: ${theme.colors.dataPink};
        }
        span {
          left: 0px;
        }
      }
    }
  }
`

const BlogPostWrapper = styled.div`
  margin-bottom: 24px;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    margin-bottom: 32px;
  }
`

const Post = styled.div`
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.05),
    0px 10px 38px rgba(23, 23, 26, 0.05);
  border-radius: ${theme.radii.large};
  background-color: white;
  padding: 2rem 1.5rem;

  .gatsby-image-wrapper {
    border-radius: ${theme.radii.large};
    margin-top: 2rem;
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      border-radius: ${theme.radii.extralarge};
    }
  }

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    padding: 3rem 4rem 2rem;
    border-radius: ${theme.radii.extralarge};
  }
  h2 {
    margin-top: 0;
    margin-bottom: 4px;
  }
  div.meta {
    color: ${theme.colors.black500};
  }
  p.excerpt {
    margin: 1.5rem 0 0;
  }
`
